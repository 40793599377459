<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <h2>
            Inclua sua Atividade :
          </h2>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Selecione o cliente :"
              label-for="cliente"
            >
              <v-select
                v-model="cliente"
                label="name"
                placeholder="Cliente"
                :options="opcoescliente"
                @input="buscarContrato()"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione o Contrato :"
              label-for="contrato"
            >
              <v-select
                v-model="contrato"
                :disabled="!cliente"
                label="name"
                placeholder="Selecione o contrato"
                :options="opcoescontrato"
                @input="BuscarObra()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Selecione a Obra :"
              label-for="obra"
            >
              <v-select
                v-model="obra"
                :disabled="!contrato || !cliente"
                label="name"
                placeholder="Selecione a Obra"
                :options="opcoesobra"
                @input="BuscarDisciplina()"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione a Disciplina :"
              label-for="disciplina"
            >
              <v-select
                v-model="disciplina"
                :disabled="!contrato || !cliente || !obra"
                label="name"
                placeholder="Selecione a Disciplina"
                :options="opcoesdisciplina"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione a Atividade :"
              label-for="atividade"
            >
              <v-select
                v-model="atividade"
                :disabled="!contrato || !cliente || !obra || !disciplina"
                label="name"
                placeholder="Atividade"
                :options="opcoesatividade"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              block
              @click="SalvarCliente()"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexIncluirAtividade',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      url: 'admin/atividade',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      cliente: null,
      opcoescliente: [],
      contrato: null,
      opcoescontrato: [],
      obra: null,
      opcoesobra: [],
      disciplina: null,
      opcoesdisciplina: [],
      atividade: null,
      opcoesatividade: [],
    }
  },
  watch: {
    cliente(newV) {
      if (!newV) this.contrato = null
    },
    contrato(newV) {
      if (!newV) this.obra = null
    },
    obra(newV) {
      if (!newV) this.disciplina = null
    },
    disciplina(newV) {
      if (!newV) this.atividade = null
    },
  },
  async created() {
    await this.$http.get('admin/clientes_ver').then(cliente => {
      this.opcoescliente = cliente.data
    })
    await this.$http.get('admin/atividade_categoria').then(atividadescat => {
      const atividadeBanco = atividadescat.data.data
      const atvcat = []
      atividadeBanco.forEach(atv => {
        atvcat.push({
          value: atv.id,
          name: atv.nome,
        })
      })
      this.opcoesatividade = atvcat
    })
  },
  methods: {
    async buscarContrato() {
      if (this.cliente === null) return
      await this.$http.get(`admin/contrato_ver/${this.cliente.value}`).then(contrato => {
        this.opcoescontrato = contrato.data
      })
    },
    async BuscarObra() {
      if (this.contrato === null) return
      await this.$http.get(`admin/obra_ver/${this.contrato.value}`).then(obra => {
        this.opcoesobra = obra.data
      })
    },
    async BuscarDisciplina() {
      if (this.obra === null) return
      await this.$http.get(`admin/disciplina_ver/${this.obra.value}`).then(disciplina => {
        this.opcoesdisciplina = disciplina.data
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    LimparCampos() {
      this.id = null
      this.cliente = null
      this.contrato = null
      this.obra = null
      this.disciplina = null
      this.atividade = null
    },
    SalvarCliente() {
      const obj = {
        cliente_id: this.cliente.value,
        contrato_id: this.contrato.value,
        obra_id: this.obra.value,
        disciplina_id: this.disciplina.value,
        atividade_id: this.atividade.value,
      }
      if (this.id === null) {
        this.$http.post('admin/atividade', obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
          }
          this.LimparCampos()
          this.reloadTable()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
